.CalendarGrid {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 30px;
}

.calendarCell {
    user-select: none;
}