.YouTubePlaylist {
    display: flex;
    flex-flow: column;
    align-items: center;
    margin-bottom: 10px;
}

.iframe-container {
  position: relative;
  overflow: hidden;
  width: 85%;
  padding-top: 47.8125%;
  /* 0.5625 * 85 */

  background: #d2d2d2;
  text-align: center;
  vertical-align: middle;
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}